.discount-range{
    appearance: none;
  
  &::-webkit-slider-runnable-track{
    width: 100%;
    height: 4px;
    background: #E9E9E9;
  }
  &::-webkit-slider-thumb{
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.1);


    height: 30px;
    width: 30px;
    border-radius: 12px;
    background: white;
    cursor: pointer;
    appearance: none;
    margin-top: -13px;
  }
}

.media-container::-webkit-scrollbar{
    height: 4px;
}
.media-container::-webkit-scrollbar-thumb{
    height: 2px;
    background: #d4d4d4;
    border-radius: 10px;
}
.media-container::-webkit-scrollbar-thumb:hover{

    background: #939393;

}

.overlay{
    opacity: 0;
    transition: all .2s ease;
}

.img-card:hover .overlay{
    opacity: 1;
    transition: opacity .2s ease;
}

.discount{
    background: none;
    width: 3rem;
    border-bottom: 1px solid #07415c58;
    margin-right: 1rem;
    font-size: 12px;
    color: #07415c;
    padding: 0rem .2rem;
    text-align: center;
}

.tab-active{
    position: relative;
    color: #F19A0E !important;
    &::after{
        content:'' ;
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 2px;
        background-color: #F19A0E;
    }
}


// .ant-tabs {
//     .ant-tabs-tab {
//         &:hover {
//           color: #F19A0E !important;
//         }
//       }
//     & .ant-tabs-tab-active .ant-tabs-tab-btn {
//         color: #F19A0E !important;
//         font-weight: 700;
//     }
//     .ant-tabs-ink-bar {
//         background-color: #F19A0E !important;
//         font-weight: 400;
//       }
// }