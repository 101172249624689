.modal-bg{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .3;
    z-index: -1;
}

.modal-active{
    opacity: 1;
    visibility: visible;
    transition: all .2s ease;
}

.modal{
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
}