.tab-active::after{
   content: "";
   position: absolute;
   bottom: -8px;
   left: 0;
   width: 100%;
   height: 2px;
   background-color: #F19A0E;
}

