.search-input {
  background: none;
}
.mobile-active {
  left: 0;
  transition: all 0.5s ease-in;
}

.mobile-sidebar {
  @media screen and (min-width: 890px) {
    display: none;
  }
}

.subCollapse {
  position: absolute;
  top: 0;
  left: 5rem;
  background-color: white;
  border-radius: 10px;
  min-width: 10rem;
}

.sub-active {
  max-height: 1000px;
  transition: all .6s ease-in;
}
.sub-close {
  max-height: 0;
  transition: all .3s ease;
}

.notification-drawer .ant-tabs {
  padding: 0px 30px;
}

// .ant-drawer-content-wrapper {
//   width: 500px !important;
// }

.notification-drawer .ant-drawer-body {
  // padding: 0px 20px !important;
  padding: 0px !important;
}

.notification-drawer .ant-drawer-header {
  border-bottom: none !important;
}

.ant-drawer-title {
  color: #07415C !important;
  font-size: 20px !important;
  font-weight: 500 !important;
}

.anticon.anticon-close {
  color: #07415C !important;
  // font-size: 20px !important;
}

.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 8px;
}

.ant-tabs-tab {
  font-size: 14px !important;
  font-weight: 400;
}
.ant-tabs-tab .ant-tabs-tab-btn {
  color: #0B4058 !important;
}
.ant-tabs-tab-active {
  font-weight: 500;
}

.ant-tabs-ink-bar {
    background-color: #F19A0E !important;
    font-weight: 400;
}

.ant-popover-inner {
  padding: 0 !important;
}

.ql-container.ql-snow {
  height: 380px !important;
  overflow-y: scroll !important;
}

.ql-container.ql-snow::-webkit-scrollbar-thumb{
  width: 4px !important;
  background-color: #07415c52;
}
.ql-tooltip.ql-editing {
  left: 3px !important;
}

// .phone-emulator {
//   position: relative;
//   width: 330px; /* Adjust the width according to your needs */
//   height: 620px; /* Adjust the height according to your needs */
//   // margin: 50px auto;
//   border: 10px solid #333;
//   border-radius: 36px;
//   background: #000;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
//   overflow: hidden;
// }

// .screen {
//   position: absolute;
//   top: 14px;
//   left: 14px;
//   right: 14px;
//   bottom: 14px;
//   background: #fff;
//   overflow: auto;
//   padding: 10px;
//   box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.5);
//   p {
//     font-size: 13px !important;
//   }
// }

.phone-emulator {
  position: relative;
  width: 330px; /* Adjust the width according to your needs (common width for iPhone) */
  height: 620px; /* Adjust the height according to your needs (common height for iPhone) */
  // margin: 50px auto;
  border: 10px solid #000;
  border-radius: 40px;
  background: #333; /* Darker background color for a realistic look */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  overflow: hidden;
}

.screen {
  position: absolute;
  top: 14px;
  left: 14px;
  right: 14px;
  bottom: 14px;
  background: #fff;
  overflow: auto;
  padding: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

/* Add some styles for a header and footer to make it look like a real phone */
// .screen::before {
//   content: '';
//   height: 10px;
//   background: #333;
//   position: absolute;
//   left: 50%;
//   transform: translateX(-50%);
//   width: 50%;
//   top: 0;
//   border-radius: 0px 0px 8px 8px;
// }

// .screen::after {
//   top: auto;
//   bottom: 0;
// }

.screen h1,
.screen h2,
.screen p {
  margin-bottom: 10px;
}

.screen p {
  font-size: 14px;
  line-height: 1.5;
}
