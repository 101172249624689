.custom-select{
    & .css-1fdsijx-ValueContainer{
          padding: 2px 10px !important;
    }

    & .css-1jqq78o-placeholder{
        color:#07415C;
        opacity: .4;
        white-space: nowrap;

    }

    & .css-1u9des2-indicatorSeparator{
        display: none;
    }
    .css-t3ipsp-control{
        outline: none !important;
        border-color: #575252 ;
        box-shadow: none;
    }
    .css-t3ipsp-control:hover{
        outline: none !important;
        border-color:  #575252 ;
        box-shadow: none;
    }

}