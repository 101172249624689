.color-picker {
  width: auto !important;
  & > div:first-child {
    height: 305px !important;
    width: 257px !important;
  }
  & > div:nth-child(2) {
    padding: 20px 0 0 0 !important;
  }
  .flexbox-fix:first-child {
    & > div:nth-child(2) div {
      border-radius: 10px !important;
    }
    & > div:nth-child(1) {
      display: none !important;
      width: 0px !important;
    }
  }
  .flexbox-fix:nth-child(2) {
    padding: 0 !important;
    & > div:nth-child(2) {
      display: none !important;
      width: 0px !important;
    }
    & > div:nth-child(1) label {
      display: none !important;
      width: 0px !important;
    }
    & > div:nth-child(1) input {
      padding: 5px 15px !important;
      font-size: 16px !important;
      text-align: left !important;
      height: auto !important;
      border-radius: 5px !important;
    }
  }
}
