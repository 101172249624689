.inp-placeholder {
    position: absolute;
    top: 50%;
    left: 23px;
    translate: 0 -50%;
    font-size: 14px;
    font-weight: 400;
    color: rgba(7, 65, 92, 0.4);
    pointer-events: none;

}


.label-focus{
    top: 0;
    left: 10px;
    font-size: 0.75em;
    transition: all .2s cubic-bezier( 0.49, 0.91, 0.56, 1.03 )  .1s;

    position: absolute;
    translate: 0 -50%;
    font-size: .70em;
    font-weight: 400;
    color: rgba(6, 42, 58, 0.815);
    pointer-events: none;
}

.input-container {
    label {
        transition: all .2s ease .1s;
        background-color: transparent;
    }

    fieldset {
        text-align: left;
        position: absolute;
        inset: -8.5px 0px 0px;
        margin: 0px;
        padding: 5px 8px;
        border-radius: 5px;
        overflow: hidden;
        max-width: 100%;
        border: 1.5px solid #bab1b1 !important;
        pointer-events: none;
      
        
    }
    
    legend {
        float: unset;
        width: auto;
        overflow: hidden;
        display: block;
        padding: 0px;
        font-size: 0.75em;
        visibility: hidden;
        max-width: 100%;
        transition: max-width 50ms cubic-bezier(0, 0, 0.2, 1) 0ms;
        white-space: nowrap;
        color: black;

    }
}
.legend-active{
   opacity: 1 !important;
   pointer-events: none;
 
}
.legend-hide{
   opacity: 0 !important;
   pointer-events: none;
   transition: all .2s ease .2s;
  
}



.input-hide{
 
    transition: all 0s ease ;
    border: none;
}
.input-show{
    border: 1.5px solid #bab1b1 !important;
    transition: all 0s ease .2s ;
    

}

.input-style{
    padding: 10px 20px;
    background:none;
    border-radius: 5px;
    width: 100%;
    font-size: 14px;
}


