.order-detials-date {
    padding: .5rem 0 !important;
    border: 1px solid #bab1b1 !important;
    border-radius: 10px !important;
    

    & input::placeholder {
        width: 100% !important;
        color: rgba(7, 65, 92, 0.4) !important;
        font-weight: 500;
        font-size: 14px;
    }
    
    & input {
        width: 100% !important;
        text-align:left !important;
        padding: 0 1.4rem;
        color: black !important;
        font-weight: 400 !important;
        font-size: 12px !important;

    }
    & >div {
        width: 100% !important;
        
    }

}