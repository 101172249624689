.custom-select-pagination {
 
    & .css-1jqq78o-placeholder{
        color:#07415C;
        opacity: .4;
        white-space: nowrap;

    }

    & .css-1u9des2-indicatorSeparator{
        display: none;
    }
    & .css-13cymwt-control{
        outline: none !important;
        border-color: rgb(186, 177, 177);
        border-width:0 !important;
        border-radius: 10px  !important ;
        box-shadow: 2px 7px 9px -8px rgba(0,0,0,0.20) !important;

    }

    

}
