.dropdown{
    translate: 0 -8%;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease-in;
}
.dropdown-active{
    translate: 0 0;
    opacity: 1;
    visibility: visible;
     transition: all .2s ease-in;
}