@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-family: "Libre Franklin", sans-serif;
}
input,
textarea {
  // border: none;
  outline: none;
}
body {
  background-color: #F5F5F5;
}

body::-webkit-scrollbar {
  width: 4px;
}
body::-webkit-scrollbar-thumb {
  width: 4px;
  background-color: rgb(7 65 92);
}

.custom-input::placeholder {
  color: #07415c66;
  font-size: 14px;
}
.custom-textarea {
  resize: none;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  
}

textarea {
  resize: none;
}

.catogery-scroll::-webkit-scrollbar-thumb{
  width: 4px !important;
  background-color: #07415c52;
}

.toolbar-wrapper {
  position: fixed;
  bottom: 32px;
  left: 50%;
  padding: 0px 24px;
  color: #fff;
  font-size: 20px;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 100px;
  transform: translateX(-50%);
}

.toolbar-wrapper .anticon {
  padding: 12px;
  cursor: pointer;
}

.toolbar-wrapper .anticon[disabled] {
  cursor: not-allowed;
  opacity: 0.3;
}

.toolbar-wrapper .anticon:hover {
  opacity: 0.3;
}

// Applying Tailwind CSS styles
h1 {
  @apply text-3xl;
}

h2 {
  @apply text-2xl;
}

h3 {
  @apply text-xl;
}
h4 {
  @apply text-lg;
}

h5 {
  @apply text-base;
}

h6 {
  @apply text-sm;
}

.ql-snow{
  .ql-picker{
      &.ql-size{
          .ql-picker-label,
          .ql-picker-item{
              &::before{
                  content: attr(data-value) !important;
              }
          }
      }
  }
  }