.react-datepicker__header {
  padding: 1rem !important;
  background: none !important;
  border-bottom: 1px solid #e3e8eb !important;
}

.react-datepicker__navigation {
  top: 10px !important;
}
.react-datepicker__day--disabled{
    background-color: #f7f7f7 !important;
}
.react-datepicker__day-name {
  color: rgb(86, 86, 86) !important;
}

.react-datepicker__day {
  color: rgb(86, 86, 86) !important;
  width: 1.8rem !important;
  margin: 0.4rem !important;
}
.react-datepicker__day-name {
  width: 1.8rem !important;
  margin: 0.4rem !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker__triangle::before {
  border: none;
}

.react-datepicker-popper[data-placement^="bottom"] {
  padding-top: 0 !important;
}
.react-datepicker-popper {
  z-index: 999 !important ;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #f19a0e !important;
  color: white !important;
}

.react-datepicker__tab-loop {
  animation: date 0.1s ease-in both !important;
}

.react-datepicker__month {
  animation: month 0.1s ease-in both !important;
}

.react-datepicker__day--outside-month {
  background-color: rgba(241, 241, 241, 0.736) !important;
  opacity: 0.6 !important;
}

.react-datepicker-popper {
  @media screen and (max-width: 890px) {
    transform: none !important;
    inset: unset !important;
  }
}
.react-datepicker__tab-loop {
  position: relative !important;
  z-index: 10;
}

.date-picker {
  background: none !important;
  text-align: center !important;
  &::placeholder {
    color: #ffffff;
  }
}
.date-picker-secondary {
  &::placeholder {
    color: #07415C;
  }
}

.date-popper {
  @media screen and (max-width: 890px) {
    width: 100vw !important;
    display: flex !important;
    justify-content: center !important;
    position: fixed !important;
    top: 30% !important;
    left: 0% !important;
    z-index: 999999 !important;
    translate: none !important;
  }

  translate: -30px 10px;
}

@media screen and (max-width: 890px) {
  .date-popper::after {
    content: "";
    position: absolute;
    top: -73%;
    left: 0;
    width: 100vw;
    height: 110vh;
    background-color: #000000;
    z-index: -1;
    opacity: 0.3;
  }
}

.date-popper .react-datepicker {
  @media screen and (max-width: 890px) {
    box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
      0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
    border-radius: 10px !important;
  }
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05) !important;
  border-radius: 5px !important;
}

.react-datepicker__input-container {
  @media screen and (max-width: 890px) {
    position: static !important;
  }
}

@keyframes date {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes month {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
.react-datepicker-popper{
  z-index: 99999;
}