.submit-btn{
  
   color: white;
   width: 100%;
   padding: .5rem 1rem;
   transition: all .2s ease;
}

.checkbox-r{
    accent-color: #F19A0E !important;
    border-color: #F19A0E !important;
}
.checkbox-r::after{
    
    accent-color: #F19A0E !important;
}