.custom-table::-webkit-scrollbar {
  height: 8px !important;
  width: 10px;
  background: none;
}

.custom-table::-webkit-scrollbar-thumb:horizontal {
  border-bottom: 4px solid rgb(233, 233, 233);
  transition: all 0.1s ease;
  width: 4px;
  background: none;
}
.custom-table::-webkit-scrollbar-thumb:vertical {
  border-right: 4px solid rgb(233, 233, 233);
  transition: all 0.1s ease;
  width: 4px;
  background: none;
}
.custom-table::-webkit-scrollbar-thumb:vertical:hover {
  border-right: 4px solid rgb(204, 203, 203);
}
.custom-table::-webkit-scrollbar-thumb:horizontal:hover {
  border-bottom: 4px solid rgb(204, 203, 203);
}

.head-items {
  &:first-child {
    border-radius: 20px 0 0 20px;
    padding-left: 40px !important;
    padding-right: 0 !important;
  }
  &:last-child {
    border-radius: 0 20px 20px 0;
    padding-right: 40px !important;
    padding-left: 0 !important;
  }
}

.table-item {
  &:first-child {
    padding-left: 40px !important;
    padding-right: 0 !important;
  }
  &:last-child {
    padding-right: 40px !important;
    padding-left: 0 !important;
  }
}

.ant-switch.dynamic-table {
  background: rgba(0, 0, 0, 0.45) !important;
}