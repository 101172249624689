.coupon-head {
    position: relative;
    color: #F19A0E !important;
    font-weight: bold;
    &::after{
        content: '';
        position: absolute;
        bottom: -10px;
        left: 0;
        width: 100%;
        height: 3px;
        background-color: #F19A0E;
    }
}