.tracker-container {
    .tracker .track-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .round {
            background-color: rgb(210, 210, 210);
            width: 10px;
            height: 10px;
            border-radius: 20px;

            div {

                width: 10px;
                height: 10px;
                border-radius: 20px;
                // background-color: #F19A0E;


            }
        }



        .track {
            background-color: rgb(210, 210, 210);
            width: 3px;
            height: 50px;

            div {
                width: 3px;
                height: 0px;
                background-color: #F19A0E;
                transition: all .2s ease .2s;
            }
        }
    }

    .tracker {
        display: flex;
        gap: 2rem;
        align-items: flex-start;


    }

}

.detials-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: right;


    .detials {


        display: flex;

        h1,
        p {
            box-sizing: border-box;
            line-height: 10px;
        }

        h1 {
            font-size: 14px;
            color: #07415C;
            margin-right: 1rem;
            font-weight: 700;
        }

        p {
            font-size: 12px;
            color: #07415C80;
        }

    }
}

.placed .round div {
    background-color: #F19A0E;
    transition: all .2s ease;
}

.placed .track div {
    height: 53px !important;
    transition: all .2s ease;
}

.dispatched .round div {
    background-color: #F19A0E;
    transition: all .2s ease .2s;
}

.dispatched .track div {
    height: 53px !important;
    transition: all .2s ease .2s;

}

.shipped .track-container >.shipped-round div {
    background-color: #F19A0E;
    transition: all .2s ease .4s;
}

.shipped .track div {
    height: 53px !important;
    transition: all .2s ease .4s;
}

.deliverd > div {
    background-color: #F19A0E;
    transition: all .2s ease .6s;
}

